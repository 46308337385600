<template>
  <div>
    <b-card header-tag="header" header-bg-variant="dark">
      <template #header>
        <h5 class="mb-0" style="color: #fff">
          <strong>{{ pageName }}</strong>
        </h5>
      </template>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr />
        </b-col>
      </b-row>

      <b-alert dismissible fade :show="showing" :variant="variant">{{ msg }}</b-alert>

      <b-row>
        <b-col cols="4">
          <b-form-group label-cols-md="3">
            <template v-slot:label> Kode Batch </template>
            <b-form-input
              v-model="filter_data.kode_batch"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Barang </template>
            <b-form-select
              v-model="filter_data.ms_barang_id"
              :options="option_barang"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label-cols-md="3">
            <template v-slot:label> Tipe Gudang </template>
            <b-form-input
              v-model="filter_data.tipe_gudang"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Jenis Barang </template>
            <b-form-select
              v-model="filter_data.ms_jenis_barang_id"
              :options="option_jenis_barang"
            ></b-form-select>
          </b-form-group>
        </b-col>
        
        <b-col cols="4">
          <b-form-group label-cols-md="3">
            <template v-slot:label> Gudang </template>
            <b-form-select
              v-model="filter_data.ms_gudang_id"
              :options="option_gudang"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="end" class="mb-1">
        <b-col cols="1">
          <b-button block variant="primary" @click="filterData()">Cari</b-button>
        </b-col>
        <b-col cols="1">
          <b-button block variant="danger" @click="getDatas(), resetFilter()">Reset</b-button>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(actions)="item">
              <!-- <b-button
                variant="success"
                class="mr-1"
                v-c-tooltip.hover.click="'List Per Gudang'"
                @click="listPerGudang(item.item)"
                ><CIcon name="cil-layers" />
                {{ item.actions }}
              </b-button> -->

              <b-button
                variant="secondary"
                class="mr-1"
                v-c-tooltip.hover.click="'Detail Per Gudang'"
                ><CIcon name="cil-info" />
                {{ item.actions }}
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <b-form-group
            label="Per Halaman"
            label-for="per-page-select"
            label-cols-md="6"
            label-align-md="left"
            label-size="md"
            style="background-color: "
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="md"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" offset-md="3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash"
export default {
  name: "allStock",
  props: ["option_gudang", "option_barang", "option_jenis_barang"],
  data() {
    return {
      file_name: "stock",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center col-1",
        },
        {
          key: "nama_barang",
          label: "Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_batch",
          label: "Kode Batch",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggal_kadaluarsa",
          label: "Kadaluarsa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? this.$moment(val).format("DD-MM-YYYY") : "-";
          },
        },
        {
          key: "qty_stock",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "hrg_beli_satuan",
          label: "Harga Beli Satuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "total_harga_sub_pembelian",
          label: "Total Harga Beli",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_satuan",
          label: "Satuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_gudang",
          label: "Gudang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_sumber_dana",
          label: "Sumber Dana",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          formatter: (val) => {
            return val || "-";
          },
        },
        {
          key: "nama_jenis_barang",
          label: "Jenis",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          formatter: (val) => {
            return val || "-";
          },
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-2 text-center",
        // },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      tableBusy: false,

      // ms_gudang_id, kode_batch, tipe_gudang, ms_barang_id, ms_jenis_barang_id
      filter_data: {
        ms_gudang_id: "",
        kode_batch: "",
        tipe_gudang: "",
        ms_barang_id: "",
        ms_jenis_barang_id: ""
      }
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");

      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;
      let res = await vm.$axios.post(`/stock/list`);
      console.log(res, 'ini ress');
      vm.items = res.data.status == 200 ? res.data.data : [];
      // vm.items.forEach((el, ix) => (el.no = ix + 1));
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1
        if(x.qty_beli){
          x.hrg_beli_satuan = vm.$numberFormat(x.total_harga_sub_pembelian / x.qty_beli, 'idr')
          x.total_harga_sub_pembelian = vm.$numberFormat(x.total_harga_sub_pembelian, 'idr')
        }else {
          x.hrg_beli_satuan = "-"
          x.total_harga_sub_pembelian = "-"
        }
        
      }
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    async filterData() {
      const vm = this
      vm.tableBusy = true;
      let req = _.omitBy(vm.filter_data, _.isEmpty)
      const res = await vm.$axios.post('/stock/list', req)
      vm.items = res.data.status == 200 ? res.data.data : [];
      // vm.items.forEach((el, ix) => (el.no = ix + 1));
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1
        x.hrg_beli_satuan = x.total_harga_sub_pembelian / x.qty_beli
      }
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
    resetFilter() {
      this.filter_data = {
        kode_batch: "",
        tipe_gudang: "",
        ms_gudang_id: "",
        ms_barang_id: "",
        ms_jenis_barang_id: "",
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
