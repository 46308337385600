<template>
  <div>
    <b-card header-tag="header" header-bg-variant="dark">
      <template #header>
        <h5 class="mb-0" style="color: #fff">
          <strong>{{ pageName }}</strong>
        </h5>
      </template>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr />
        </b-col>
      </b-row>

      <b-alert dismissible fade :show="showing" :variant="variant">{{ msg }}</b-alert>

      <b-row>
        <b-col md="6" offset-md="6">
          <b-form-group
            label="Cari"
            label-for="filter-input"
            label-cols-md="3"
            label-align-md="right"
            label-size="md"
          >
            <b-input-group size="md">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Ketik disini untuk mencari ..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''" variant="danger">
                  Hapus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <!-- <template #cell(no)="item">
              {{ item.index + 1 }}
            </template> -->
            <template #cell(actions)="item">
              <b-button
                variant="success"
                class="mr-1"
                v-c-tooltip.hover.click="'List Per Gudang'"
                @click="listPerGudang(item.item)"
                ><CIcon name="cil-layers" />
                {{ item.actions }}
              </b-button>

              <!-- <b-button
                variant="secondary"
                class="mr-1"
                v-c-tooltip.hover.click="'Detail Per Gudang'"
                @click="listDetailPerGudang(item.item)"
                ><CIcon name="cil-info" />
                {{ item.actions }}
              </b-button> -->
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <b-form-group
            label="Per Halaman"
            label-for="per-page-select"
            label-cols-md="6"
            label-align-md="left"
            label-size="md"
            style="background-color: "
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="md"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" offset-md="3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "byGudang",
  data() {
    return {
      file_name: "stock",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center col-1",
        },
        {
          key: "nama_gudang",
          label: "Gudang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      option_puskesmas: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;
      let res = await vm.$axios.post(`/ms_gudang/list`);

      vm.items = res.data.status == 200 ? res.data.data : [];
      vm.items.forEach((el, ix) => el.no = ix + 1)
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    listPerGudang(val) {
      // console.log("listpergudang", val);
      this.$store.commit("set_gudang_id", val.ms_gudang_id);
      this.$router.push(`/gudang/stock_by_gudang/${this.$store.state.puskesmas_id}/${val.ms_gudang_id}`);
    },
    listDetailPerGudang(val) {
      // console.log("listdetailpergudang", val);
      this.$store.commit("set_gudang_id", val.ms_gudang_id);
      this.$router.push(
        "/gudang/stock_by_gudang_detail/" +
          this.$store.state.puskesmas_id +
          "/" +
          val.ms_gudang_id
      );
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
