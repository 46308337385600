<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
        
          <b-tabs content-class="mt-3">
            <b-tab title="All Stock" lazy @click="openTabs()">
              <AllStock :option_gudang="option_gudang" :option_barang="option_barang" :option_jenis_barang="option_jenis_barang" />
            </b-tab>
            <b-tab title="By Gudang" lazy @click="super_count = 0">
              <ByGudang />
            </b-tab>
            <b-tab v-if="super_count == 15" title="Test Input" lazy>
              <TestInputTags />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AllStock from "./allStock/allStock.vue";
import ByGudang from "./byGudang/byGudang.vue";
import TestInputTags from "./testInputTags.vue";

export default {
  components: {
    AllStock,
    ByGudang,
    TestInputTags
},
  name: "stock",
  data() {
    return {
      file_name: "stock",
      showing: false,
      variant: "success",
      msg: "",
      option_gudang: [],
      option_barang: [],
      option_jenis_barang: [],
      option_role: [
        { value: "admin", text: "admin" },
        { value: "gudang", text: "gudang" },
        { value: "kasir", text: "kasir" },
        { value: "dokter", text: "dokter" },
        { value: "bidan", text: "bidan" },
        { value: "perawat", text: "perawat" },
        { value: "sanitarian", text: "sanitarian" },
        { value: "nutrisionis", text: "nutrisionis" },
      ],
      super_count: 0,
    };
  },
  computed: {
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    async getDatas() {
      const vm = this;

      //! gudang
      let option_gudang = await vm.$axios.post("/ms_gudang/list");
      vm.option_gudang =
        option_gudang.data.status == 200
          ? option_gudang.data.data.map((x) => {
              return { value: x.ms_gudang_id, text: x.nama_gudang };
            })
          : [];
      
      //! barang
      let option_barang = await vm.$axios.post("/ms_barang/list");
      vm.option_barang =
        option_barang.data.status == 200
          ? option_barang.data.data.map((x) => {
              return { value: x.ms_barang_id, text: x.nama_barang };
            })
          : [];
      
      //! jenis_barang
      let option_jenis_barang = await vm.$axios.post("/ms_jenis_barang/list");
      vm.option_jenis_barang =
        option_jenis_barang.data.status == 200
          ? option_jenis_barang.data.data.map((x) => {
              return { value: x.ms_jenis_barang_id, text: x.nama_jenis_barang };
            })
          : [];
    },
    openTabs() {
      this.super_count++
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
