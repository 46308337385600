<template>
  <div>
    <!-- <b-form @submit="handleSubmit">
      <b-form-input
        v-model="inputValue"
        list="tagList"
        @input="handleInput"
        @keypress.enter.prevent="addTag"
      ></b-form-input>
      <datalist id="tagList">
        <option v-for="tag in tags" :key="tag">{{ tag }}</option>
      </datalist>
      <b-form-tags v-model="selectedTags"></b-form-tags>
      <button type="submit" class="btn btn-primary">Submit</button>
    </b-form> -->

    <b-form-tags v-model="selectedTags" no-outer-focus class="mb-2">
      <template
        v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }"
      >
        <b-input-group class="mb-2">
          <div class="d-inline-block" style="font-size: 1.5rem">
            <b-form-tag
              v-for="tag in tags"
              @remove="removeTag(tag)"
              :key="tag"
              :title="tag"
              :variant="tagVariant"
              class="mr-1"
              >{{ tag }}</b-form-tag
            >
          </div>
          <b-form-input
            v-bind="inputAttrs"
            v-on="inputHandlers"
            placeholder="New tag - Press enter to add"
            class="form-control"
            @input="handleInput"
            v-model="inputValue"
            debounce="500"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="addTag()" variant="primary">Add</b-button>
          </b-input-group-append>
        </b-input-group>

        <!-- Custom suggestion dropdown -->
        <ul class="list-group mt-2">
          <li
            class="list-group-item"
            v-for="tag in datalistTags"
            :key="tag"
            @click="addTagFromDatalist(tag)"
          >
            {{ tag }}
          </li>
        </ul>
      </template>
    </b-form-tags>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   inputValue: "",
      //   tags: ["Paramex", "Panadol", "Procold"], // Sample initial tags
      //   selectedTags: [],

      inputValue: "",
      tags: ["Paramex", "Panadol", "Procold"], // Sample initial tags
      selectedTags: [],
      datalistTags: [],
    };
  },

  methods: {
    // handleInput() {
    //   // Handle input and update the datalist options
    //   // For simplicity, we assume the options are always updated based on the tags array
    //   this.$nextTick(() => {
    //     const datalist = document.getElementById("tagList");
    //     datalist.innerHTML = "";
    //     this.tags.forEach((tag) => {
    //       const option = document.createElement("option");
    //       option.value = tag;
    //       datalist.appendChild(option);
    //     });
    //   });
    // },
    // addTag() {
    //   if (this.inputValue.trim() !== "") {
    //     const newTag = this.inputValue.trim();
    //     if (!this.selectedTags.includes(newTag)) {
    //       this.selectedTags.push(newTag);
    //     }
    //     this.inputValue = "";
    //   }
    // },
    // handleSubmit(event) {
    //   event.preventDefault();
    //   // Handle form submission, e.g., send data to the server
    //   console.log("Selected Tags:", this.selectedTags);
    // },

    handleInput() {
      // Handle input and update the datalist suggestions
      const input = this.inputValue.toLowerCase();
      this.datalistTags = this.tags.filter((tag) => tag.toLowerCase().includes(input));
    },
    addTag() {
      if (this.inputValue.trim() !== "") {
        const newTag = this.inputValue.trim();
        if (!this.selectedTags.includes(newTag)) {
          this.selectedTags.push(newTag);
        }
        this.inputValue = "";
        this.datalistTags = [];
      }
    },
    addTagFromDatalist(tag) {
      if (!this.selectedTags.includes(tag)) {
        this.selectedTags.push(tag);
      }
      this.inputValue = "";
      this.datalistTags = [];
    },
    removeTag(tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index !== -1) {
        this.selectedTags.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped></style>
